
.home{

    display: flex;
    height:100vh;
    flex-direction: column;
    margin-bottom: 3rem;
    
}

.content{

    display: flex;
    flex-grow: 1;
    justify-content: center;   
    padding-top: 5rem;
    
    
}



