.taskbox{

    width: 60rem;
    height: 100px;
    border-top-right-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    background:white;
    margin-bottom: 0.1rem;
}

.taskbox :hover{

    cursor: pointer;
    background:#ADD8E6;

}

.newtaskbox{

    width: 60rem;
    height: 100px;
    border-top-right-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    background:white;
    margin-bottom: 0.1rem;
    border: 1px solid aqua;
    
}


.taskbox-items{

    display: flex;
    width:100%;
    height: 100%;
   
    

}

.newtaskbox .text-items{
    
    padding: 1rem;
    text-overflow: ellipsis;
    overflow-y:hidden;
    overflow-x:auto;
    white-space: nowrap;
 
}

.taskbox .text-items{
    padding: 1rem;
    font-style: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.sub-title{
    padding-left: 1rem;
    
}

.delete-icon{

   padding-top:2rem;
   padding-right: 1rem;
   
}

.save-icon{

    cursor: pointer;
}

.close-icon :hover{
    cursor: pointer;
}

.close-icon{
    padding-top:2rem;
    padding-right: 1rem;
}

.save-icon{

    padding-top:2rem;
    padding-right: 1rem;
    
 }
.edit-icon{

    padding-top:2rem;
    padding-right: 1rem;
    
 }

 .clipboard-add{

    margin-bottom: 0.5rem;
 }

 input[type=text] {
    width: 50rem;
    background-color: transparent;
    border: 0px solid;
    outline: none;
    font-size: 20px;
       

  }



 
@media (max-width: 950px) {
   
    .taskbox{
        width: 20rem;
        height: auto;
        
    }

    .newtaskbox{
        width:20rem;
        height: auto;
    }
 }

 
